<template>
  <section>
    <v-dialog persistent v-model="success_dialog" max-width="400">
      <v-card class="pa-5">
        <div class="text-center primary--text poppins fw600 f18">Please wait for the approval of the Admin</div>
        <v-card-subtitle>
          <div class="roboto mt-1 f12 text-center">Once approved, an email notification will be sent to the user. You can also track the status on the 'Enrollment History' tab.</div>
        </v-card-subtitle>
        <div class="d-flex align-center justify-space-between">
          <v-btn
            @click="success_dialog = false, dialog = true"
            color="primary"
            class="text-capitalize poppins fw600 f12 small"
            dense
            outlined
          >
            Enroll Another
          </v-btn>
          <v-btn
              @click="() => {
                success_dialog = false
                $emit('close')
              }"
              color="primary"
              class="text-capitalize poppins fw600 f12 small"
              dense
            >
              Okay
            </v-btn>
        </div>
        <!-- <v-form ref="form">
            <v-card-text>
              <label class="poppins fw500 f12 secondary-1--text">EMAIL ADDRESS* </label>
              <v-text-field v-model="email" outlined dense hide-details="auto" type="email" :error-messages="errors.email"
                  class="general-custom-field roboto f14 secondary-1--text fw500"
                  required
                  :rules="[ v => /.+@.+\..+/.test(v) || 'E-mail must be valid', v => !!v || 'E-mail is required']"/>
            </v-card-text>
            <v-card-text>
              <label class="poppins fw500 f12 secondary-1--text pt-5">ADD COURSES*</label>
              <v-select 
              outlined 
              dense 
              class="general-custom-field roboto f12 secondary-1--text fw500"
              :items="courses"
              v-model="course_id"
              item-text="title"
              item-value="id"
              hide-details="auto"
              :error-messages="errors.course_id"
              multiple
              >
              </v-select>
            </v-card-text>
            <v-card-text class="d-flex flex-column">
              <div class="bulk-border pa-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <div class="text-center">
                  <v-icon color="rgba(63, 93, 208, 0.12)" size="68">mdi-cloud-upload-outline</v-icon>
                  <div class="secondary--text"><a href="#" @click="$refs.file.click()" class="primary--text text-decoration-none">Browse</a> or Drag file to upload</div>
                </div>
              </div>
              <section>
                <v-chip 
                    v-if="image"
                    class="ma-1"
                    color="primary"
                    small
                    outlined
                    close
                    @click:close="remove(i)"
                    >
                    <v-icon small left>
                        mdi-image
                    </v-icon>
                    {{ image.name }}
                </v-chip>

              </section>
                <input class="d-none" type="file" ref="file" accept=".jpeg,.png,.jpg" @change="addAttachment"/>
                <span class="danger--text f10" v-if="errors && errors.image">{{ errors.image[0] }}</span>
            </v-card-text>
            <v-card-text>
            <label class="poppins fw500 f12 secondary-1--text">OR NUMBER</label>
            <v-text-field v-model="or_number" outlined dense hide-details="auto" type="email" :error-messages="errors.or_number"
                class="general-custom-field roboto f14 secondary-1--text fw500"/>
            </v-card-text>
        </v-form>
        <v-card-actions class="">
          <v-spacer />
          <v-btn text class="poppins fw600 text-capitalize" @click="$emit('close')"
            >Cancel</v-btn
          >
          <v-btn
            v-if="!saving"
            class="poppins fw500 text-capitalize"
            color="primary"
            @click="submit"
          >
            Submit
          </v-btn>
          <v-btn v-else class="poppins fw500 text-capitalize" color="primary">
            Submitting...
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <h4 class="primary--text poppins fw600">Enroll a Student</h4>
          <v-btn icon @click="$emit('close')">
            <v-icon size="18">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" class="px-3 pb-3">
            <v-card-text>
              <FormTextFieldRequired
                :label="'EMAIL ADDRESS *'"
                :error="errors.email"
                :value.sync="email"
              />
              <!-- <label class="poppins fw500 f12 secondary-1--text"> </label>
              <v-text-field v-model="email" outlined dense hide-details="auto" type="email" :error-messages="errors.email"
                  class="general-custom-field roboto f14 secondary-1--text fw500"
                  required
                  :rules="[ v => /.+@.+\..+/.test(v) || 'E-mail must be valid', v => !!v || 'E-mail is required']"/> -->

                  <label class="caption">COURSE *</label>
                  <v-select 
                    outlined 
                    dense 
                    :items="courses"
                    v-model="course_id"
                    item-text="title"
                    item-value="id"
                    hide-details="auto"
                    :rules="[ v => !!v || 'This is required']"
                    :error-messages="errors.course_id"
                    required
                    class="general-custom-field mb-1"
                    :loading="loading"
                  >
                  </v-select>
              <label class="caption">UPLOAD RECEIPT *</label>
              <FormUploadFile 
                :accepted_types="'(.jpeg, .png, .jpg, .pdf)'"
                :accept="'.jpeg,.png,.jpg,.pdf'"
                :accepted_types_list="['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']"
                :multiple="false"
                @acceptFile="addAttachment"/>
              <!-- <div class="bulk-border pa-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <div class="text-center">
                  <v-icon color="rgba(63, 93, 208, 0.12)" size="68">mdi-cloud-upload-outline</v-icon>
                  <div class="secondary--text"><a href="#" @click="$refs.file.click()" class="primary--text text-decoration-none">Browse</a> or Drag file to upload</div>
                </div>
              </div>
              <section> -->
                <v-chip 
                    v-if="image"
                    color="primary"
                    small
                    outlined
                    close
                    @click:close="remove(i)"
                    >
                    <v-icon small left>
                        mdi-image
                    </v-icon>
                    {{ image.name }}
                </v-chip>
<!-- 
              </section> -->
                <!-- <input class="d-none" type="file" ref="file" accept=".jpeg,.png,.jpg" @change="addAttachment"/> -->
                <div class="error--text f11 px-5" v-if="errors && errors.image">{{ errors.image[0] }}</div>
                <FormTextFieldRequired
                  class="mt-1"
                  :label="'OR NUMBER'"
                  :error="errors.or_number"
                  :value.sync="or_number"
                />
            </v-card-text>
            <alert 
              v-if="msg.show"
              :show = "msg.show"
              :text = "msg.text"
              :type = "msg.type"
            />
        </v-form>
        <v-card-actions class="">
          <v-spacer />
          <v-btn text class="poppins fw600 text-capitalize" @click="$emit('close')"
            >Cancel</v-btn
          >
          <v-btn
            :loading="saving"
            class="poppins fw500 text-capitalize"
            color="primary"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["dialog"],
  data: () => ({
    email: null,
    or_number: null,
    course_id: null,
    image: null,
    saving: false,
    success_dialog: false,
    courses: [],
    errors: {},
    search: '',
    loading: false,
    msg: {
      show: false,
      text: '',
      type: ''
    }
  }),
  methods: {
    ...mapActions("instructor", ["enrollStudentAction", "getEnrollStudentAction", "getAllCoursesAction"]),
    
    submit() {
      this.errors = {}
      this.msg = {
        show: false,
        text: "",
        type: ""
      }

      if (this.$refs.form.validate()) {
          this.saving = true

          const params = new FormData()
          params.append('_method', "POST")
          params.append('email', this.email)
          params.append(`course_id`, this.course_id)

          // this.course_id.forEach((item, i) => {
          //     params.append(`course_id[${i}]`, item)
          // })

          // this.image.forEach((item, i) => {
          //     params.append(`image[${i}]`, item)
          // })

          params.append(`image`, this.image)
          params.append('official_receipt_no', this.or_number)

          this.enrollStudentAction(params).then(res => {
            this.dialog = false
            this.success_dialog = true
            this.resetForm()
            this.saving = false
          }).catch(err => {
            console.log(err)
            if(err.errors) {
              this.errors = err.errors
            } else if(err.data.length === 0) {
              this.msg = {
                show: true,
                text: err.message,
                type: "error"
              }
            }
          }).finally(() => {
            this.saving = false
          })
        }
      },
      resetForm() {
        this.$refs.form.reset()
        this.image = null
      },
      addAttachment(e) {
        // this.image.push(this.$refs.file.files[0])
        this.image = e
      },
      dragover(e) {
        e.preventDefault()
        e.currentTarget.classList.add('bulk-border-drag');
      },
      dragleave(e) {
        e.preventDefault()
        e.currentTarget.classList.remove('bulk-border-drag');
      },
      drop(e) {
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.accept()
        e.currentTarget.classList.remove('bulk-border-drag');
      },
      accept() {
        this.image = this.$refs.file.files[0]
      },
      remove(i = 0){
        // this.image.splice(i, 1)
        this.image = null
      }
    },
    watch: {
      // error(val) {
      //   if(Object.keys(val).length) {
      //     this.errors = val
      //   }
      // },
      dialog(val) {

        if(val) {
          this.loading = true
          this.getAllCoursesAction().then((res) => {
            this.courses = res
          }).finally(() => {
            this.loading = false
          })
        }

        this.image = null
        this.$refs.file.files = []
        this.resetForm()
        this.errors = {}
        this.msg = {
          show: false,
          text: "",
          type: ""
        }
      }
    },
};
</script>