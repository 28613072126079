<template>
  <section id="top-bar">
    <v-app-bar app color="app-body" flat height="100">
      <v-row justify="center" align="center" class="my-4">
        <v-col cols="12" xl="11">
          <v-card class="custom-border border d-flex flex-row align-center pa-2 my-2 poppins fw600 secondary-1--text text-wrap">
            <v-btn class="hidden-lg-and-up" icon @click="$emit('showDrawer')">
              <v-icon size="20">mdi-menu</v-icon>
            </v-btn>
              {{ $route.name.replace('Admin ', "") }}
            <v-spacer/>
            <div class="d-flex align-center">
            <!--   <div class="">
                <v-text-field 
                  class="poppins f14" 
                  dense 
                  flat 
                  solo 
                  prepend-inner-icon="mdi-magnify" 
                  hide-details 
                  placeholder="Search"
                />
              </div> -->
              <!-- <v-menu v-if="$route.name === 'Admin User Management'" offset-y transition="slide-y-transition" bottom duration :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    text 
                    class="text-capitalize poppins fw600 f14 small mr-3"
                    v-bind="attrs"
                    v-on="on"
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    Filter
                    <v-icon color="primary" right>
                      mdi-tune
                    </v-icon>
                  </v-btn>
                  <v-btn 
                    icon 
                    text
                    class="text-capitalize poppins fw600 f14 small hidden-sm-and-up"
                    v-bind="attrs"
                    v-on="on"
                    v-else
                  >
                    <v-icon color="primary">
                      mdi-tune
                    </v-icon>
                  </v-btn>
                </template>
                <v-card width="300">
                  <v-card-text>
                    <div class="roboto fw600">Account Type</div>
                    <v-chip-group v-model="filter.role">
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500" >User</v-chip>
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">Instructor</v-chip>
                    </v-chip-group>
                
                    <div class="roboto fw600 mt-3 mb-2">Courses</div>
                    <v-select 
                      outlined 
                      dense 
                      class="general-custom-field roboto f14 secondary-1--text fw500"
                      :items="courses"
                      v-model="filter.course_id"
                      item-text="title"
                      item-value="id"
                      hide-details="auto"
                      multiple
                    >
                    </v-select>

                    <div class="roboto fw600 mt-3">Status</div>
                    <v-chip-group v-model="filter.status">
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">Active</v-chip>
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">Inactive</v-chip>
                    </v-chip-group>
                    <div class="roboto fw600 mt-3">Date Added</div>
                    <v-chip-group column v-model="filter.created_at">
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">Today</v-chip>
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">This Week</v-chip>
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">This Month</v-chip>
                      <v-chip filter outlined label color="secondary" class="text-capitalize fw500">This Year</v-chip>
                    </v-chip-group>
                  </v-card-text> 
                  <v-divider/>
                  <v-card-actions class="d-flex justify-space-between align-center">
                    <v-btn text class="text-capitalize poppins" @click="reset">Clear Filters</v-btn>
                    <v-btn width="120" color="primary" class="text-capitalize poppins" @click="filterUsers">Filter</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu> -->
              <!-- <v-btn icon>
                <v-icon>mdi-weather-night</v-icon>
              </v-btn> -->
            </div>
            
            <v-btn v-if="$vuetify.breakpoint.smAndUp" dense @click="$emit('add')" color="primary" class="text-capitalize poppins fw600 f11 small mr-1">
              Upload User
            </v-btn>
            <v-btn 
              icon 
              v-else
              dense
              @click="$emit('add')" color="primary" class="text-capitalize poppins fw600 f11">
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-btn>
            <v-btn v-if="$vuetify.breakpoint.smAndUp" dense @click="$emit('enroll')" color="primary" outlined class="text-capitalize poppins fw600 f11 small mr-3">
              Enroll Users
            </v-btn>
            <v-btn 
              icon 
              dense
              v-else
              @click="$emit('enroll')" color="primary" class="text-capitalize poppins fw600 f11 mr-3">
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-btn>
            <v-avatar size="30">
              <img
                alt="Avatar"
                :src="user.image?user.image.url:require('../../assets/default-photo.png')"
                v-on:error="user.image = null"
              >
            </v-avatar>
            <v-menu
              offset-y
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    small>
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                </v-btn>
              </template>
              <CommonToolsVue :role="'admin'"/>
            </v-menu>
          </v-card>
        </v-col>
      </v-row>
      <toaster
        v-if="alert.show"
        :show="alert.show"
        :text="alert.text"
        :type="alert.type"
        @close="alert.show = false"
      />
    </v-app-bar>
  </section>  
</template>

<script>
import moment from 'moment'
import { mapMutations, mapState, mapActions } from 'vuex'
import CommonToolsVue from '@/components/reusable/CommonTools.vue';

export default {
  props: ["user"],
  components: {
    CommonToolsVue
  },
  data: () => ({
    items: ['User','Teacher', 'Admin'],
    dialog: false,
    role: '',
    filter: {
      role: null,
      class_category_id: [],
      course_id: [],
      status: null,
      created_at: null
    }
  }),
  mounted(){
    this.reset()
    this.getRegistrationTablesAction()
  },
  created(){
    this.reset()
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState('admin', {
      users: (state) => state.users,
      courses: (state) => state.courses,
    }),
  },
  methods: {
    ...mapMutations('admin', ['filteredUsersMutation']),
    ...mapActions('admin', ['getRegistrationTablesAction']),

    ...mapState({
      tenant: (state) => state.tenant
    }),

    reset(){
      this.filteredUsersMutation(this.users)
      this.filter = {
        role: null,
        class_category_id: null,
        course_id: [],
        status: null,
        created_at: null
      }
    },

    filterUsers(){
      if( this.users.length > 0 ) {
        let { role, course_id, status, created_at } = this.filter
        let _users = this.users
        if ( role !== null ) {
          _users = _users.filter(item => item.role === (role ? 'INSTRUCTOR' : 'USER'))
        }
        if ( course_id.length > 0) {
          _users = _users.filter(item => item.courses !== [] && item.courses.some(_course_id => course_id.includes(_course_id)))
        }
        if ( status !== null ) {
          _users = _users.filter(item => item.status === (status ? 'inactive' : 'active'))
        }
        if( created_at !== null ) {
          if( created_at === 0 ) {
            _users = _users.filter(i => 
              this.$dateFormat.mmDDyy(i.created_at) == this.$dateFormat.mmDDyy(new Date())
            )
          } else {
            
            let type = null
            if(created_at === 1) type = 'week'
            if(created_at === 2) type = 'month'
            if(created_at === 3) type = 'year'

            _users = _users.filter(i => {
              let item_created_at = moment(i.created_at)
              let from_date = moment().startOf(type)
              let to_date = moment().endOf(type)

              return from_date <= item_created_at && to_date >= item_created_at
            })
          }
        }

        if(_users.length === 0) {
          _users = 'EMPTY'
        }

        this.filteredUsersMutation(_users)
      }
    }
  },
}
</script>
